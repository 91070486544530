<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.role')"
                label-for="role"
                :state="errors && errors.role ? false : null"
              >
                <b-form-select
                  v-model="item.role"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  input-id="role"
                />

                <b-form-invalid-feedback v-if="errors && errors.role">
                  {{ errors.role[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.locale')"
                label-for="locale"
              >
                <v-select
                  v-model="item.locale"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="languagesSelect"
                  input-id="locale"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <b-form-invalid-feedback v-if="errors && errors.locale">
                  {{ errors.locale[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.commission')"
                label-for="commission"
              >
                <b-form-input
                  id="commission"
                  v-model="item.commission"
                  type="number"
                  step="0.1"
                  min="0"
                  max="99"
                  :state="errors && errors.commission ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.commission">
                  {{ errors.commission[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.status')"
                label-for="status"
                :state="errors && errors.status ? false : null"
              >
                <v-select
                  v-model="item.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="userStatusOptions"
                  input-id="status"
                  :clearable="false"
                  class="w-100"
                  :reduce="val => val.value"
                />

                <b-form-invalid-feedback v-if="errors && errors.status">
                  {{ errors.status[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.first_name')"
                label-for="first_name"
              >
                <b-form-input
                  id="first_name"
                  v-model="item.first_name"
                  :state="errors && errors.first_name ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.first_name">
                  {{ errors.first_name[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.last_name')"
                label-for="last_name"
              >
                <b-form-input
                  id="last_name"
                  v-model="item.last_name"
                  :state="errors && errors.last_name ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.last_name">
                  {{ errors.last_name[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.email')"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="item.email"
                  :state="errors && errors.email ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.email">
                  {{ errors.email[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.phone')"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="item.phone"
                  :state="errors && errors.phone ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.phone">
                  {{ errors.phone[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.password')"
                label-for="account-password"
              >
                <b-form-input
                  v-model="item.password"
                  name="password"
                  :placeholder="$t('admin.labels.password')"
                  :state="errors && errors.password ? false : null"
                />
                <b-form-invalid-feedback v-if="errors && errors.password">
                  {{ errors.password[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

          </b-row>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('admin.buttons.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'users-index' }"
          >
            {{ $t('admin.buttons.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { required, alphaNum, email } from '@validations'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      required,
      alphaNum,
      email,
      roleOptions: [],
      languages: [],
      languagesSelect: [],
      item: null,
    }
  },
  async beforeCreate() {
    await this.$http('/api/languages')
      .then(response => {
        this.languages = response.data.data
        this.languagesSelect = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })
    await this.$http.get('/api/roles').then(response => {
      const { data } = response.data

      this.roleOptions = this.$options.filters.transformForBSelect(data, 'name', 'title')
    })

    this.item = await this.transformData()
  },
  methods: {
    async onSubmit() {
      const data = {
        first_name: this.item.first_name,
        last_name: this.item.last_name,
        email: this.item.email,
        phone: this.item.phone,
        role: this.item.role,
        locale: this.item.locale,
        password: this.item.password,
        commission: this.item.commission,
        status: this.item.status,
      }

      this.$http.post('/api/users', data)
        .then(() => {
          router.replace({ name: 'users-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData() {
      return {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        role: null,
        locale: false,
        commission: 0,
        status: 'base',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
